import type { ListResponse } from 'api';

export enum FrontappLogStatuses {
  FAILED = 'failed',
  SUCCEED = 'succeed',
  NEW = 'new',
  NO_DOCS = 'no_documents',
}

export enum FrontappRelatedDocs {
  none = 'none',
  one = 'one',
  several = 'several',
}

export type FrontappConfigInbox = {
  id: string;
  prompt_id: number | null;
  tags_to_skip_ai_tagging: Record<string, string>;
  application_name: string;
  indexes: string[];
  product_id: number | null;
  allowed_tags: Record<string, string>;
  created_by: string | null;
  updated_by: string | null;
  created_at: Date;
  updated_at: Date;
};

export type FrontappMetric = {
  created_at: Record<string, number>;
  inbox_email: Record<string, number>;
  status: Record<string, number>;
  indexes: Record<string, number>;
  related_documents: Partial<Record<FrontappRelatedDocs, number>>;
};

export type FrontappLog = {
  id: number;
  inbox_id: string;
  message_id: string;
  request_id: string;
  conversation_id: string;
  question: string;
  prompt: string;
  answer: string;
  indexes?: string[] | null;
  references_ids: string[] | null;

  inbox_email: string;
  sender_email: string;

  status: FrontappLogStatuses;
  updated_at: string;
  created_at: string;
  finished_at: string | null;
};

export type FrontappLogSingle = FrontappLog & {
  payload: object | null;
  traceback: string | null;
};

export type FrontappLogs = ListResponse<Omit<FrontappLog, 'payload' | 'traceback'>>;

export type FrontappConfig = Array<FrontappConfigInbox>;
